import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { title: "首页" },
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    meta: { title: "关于尼贝" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/news",
    name: "news",
    meta: { title: "新闻资讯" },
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/NewsView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: { title: "联系我们" },
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/business",
    name: "business",
    meta: { title: "服务与支持" },
    component: () =>
      import(/* webpackChunkName: "business" */ "../views/BusinessView.vue"),
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
  { path: "/:catchAll(.*)", redirect: "/404", hidden: true },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
