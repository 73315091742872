import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/styles/base.css";
/* import VueAwesomeSwiper from "vue-awesome-swiper"; */
import "swiper/css";
initApp();

createApp(App) /* .use(VueAwesomeSwiper) */
  .use(store)
  .use(router)
  .mount("#app");

function initApp() {
  window.onpageshow = (event) => {
    if (event.persisted) {
      window.location.reload();
    }
  };
  router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.query.title) {
      to.meta.title = decodeURIComponent(to.query.title);
    }
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    next();
  });
}
