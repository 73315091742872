<template>
  <div class="NavTable">
    <div class="NavWrap">
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/logo.png" alt="" srcset=""
        /></router-link>
      </div>
      <div class="navList">
        <div
          class="navItem"
          :class="$route.path === item.path ? 'active' : ''"
          v-for="(item, index) in pageItem"
          :key="index"
        >
          <router-link :to="item.path" class="">{{ item.title }}</router-link>
        </div>
      </div>

      <div class="contact">
        <router-link to="/contact" class="btn m_button_style_7"
          >联系我们</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavTable",
  props: {},
  data() {
    return {
      pageItem: [
        {
          title: "首页",
          path: "/",
        },
        {
          title: "服务与支持",
          path: "/business",
        },
        /*  {
          title: "新闻资讯",
          path: "/news",
        }, */
        {
          title: "关于尼贝",
          path: "/about",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.NavTable {
  background-color: #fff;
  padding: 20px;
  height: 100px;
  width: 1250px;
  margin: 0 auto;
  .NavWrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    height: 100px;
    .logo {
      img {
        width: 80px;
      }
    }
    .navList {
      width: 50%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      line-height: 56px;
      .navItem {
        border-top-width: 0px;
        border-right-width: 0px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        height: 100%;
      }
      a {
        font-size: 16px;
        height: 100%;
      }
      .active {
        color: rgb(7, 108, 224);
        border-color: #076ce0;
        border-bottom-width: 2px;
        border-style: solid;
      }
    }
    .contact {
      position: relative;
      font-size: 26px;
      line-height: 52px;
      font-family: Microsoft YaHei;
      overflow: hidden;
    }
  }
}
</style>
