<template>
  <NavTable />
  <router-view />
  <FooterEx />
</template>

<script>
// @ is an alias to /src
import NavTable from "@/components/NavTable";
import FooterEx from "@/components/FooterEx";

export default {
  name: "AboutView",
  components: {
    NavTable,
    FooterEx,
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
