<template>
  <div id="footerEx">
    <div class="footerCon">
      <div class="copyreserved">
        © 尼贝 · 河北省尼贝网络科技有限公司 · ICP备案证号：
        <a href="http://beian.miit.gov.cn" style="color: #ffffff"
          >冀ICP备2023030383号-1</a
        >&nbsp;|&nbsp;
        <!--  <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=xxx"
          style="color: white"
          ><i class="img_police"></i>xx公网安备 xxx号</a
        > -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterEx",
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.copyreserved {
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-top: 1px solid #323232;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: rgba(255, 255, 255, 0.6);
  width: 100%;
  background: #0e0e0e;
  overflow: hidden;
}
</style>
